




















































import {Component, Emit, Prop, Vue} from 'vue-property-decorator';
import {ILanguage} from '@/types/TranslationTypes';
import {required} from '../../utils/validationRules';
import TranslationService from '../../services/TranslationService';
import {NotificationTypes, notify, resolveError} from '@/utils/notifications';

@Component({
  name: 'LanguageForm',
})
export default class LanguageForm extends Vue {
  @Prop()
  value!: boolean;

  @Prop()
  isCreateMode!: boolean;

  @Prop()
  lang!: ILanguage;

  loading: boolean = false;

  rules = {
    name: [required],
    shortcut: [required],
  };

  @Emit('input')
  toggleVisibility() {
    // @ts-ignore
    this.$refs.form.reset();
    return !this.value;
  }

  async createLang() {
    await TranslationService.storeLang(this.lang);
  }

  async editLang() {
    await TranslationService.updateLang(this.lang);
  }

  async submit() {
    // @ts-ignore
    if (this.$refs.form.validate()) {
      try {
        if (this.isCreateMode) {
          await this.createLang();
        } else {
          await this.editLang();
        }
        notify(NotificationTypes.success, this.$t('SYSTEM_SAVED'));
        this.$emit('saved');
        this.toggleVisibility();
      } catch (e) {
        resolveError(e, 'save');
      }
    }
  }
}
